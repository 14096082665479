import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import config from '../utils/config';

const appUrl = config.APP_URL;
const aliasDomains = config.APP_ALIAS_URLS;

export default function useHandleAliasDomainsNavigation() {
  const location = useLocation();
  const isDomainAlias = aliasDomains.some(domain => location?.origin?.includes(domain));
  useEffect(() => {
    if (isDomainAlias) {
      navigate(appUrl + '/brands' + location.pathname, {
        replace: true
      });
    }
  }, []);
  return isDomainAlias;
}
